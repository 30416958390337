import {url} from "../utils";

class Analytics {
    constructor() {

    }

    static userId = null;

    static setUserId(id) {
        Analytics.userId = id;
    }

    static track(generic_name, data) {
        data.eventData['event_type_id'] = data.type_id;
        Analytics.trackInGA(generic_name, data.eventData);
        var localData = {
            _token: $('input[name="_token"]:first').val(),
            'type_id': data.type_id,
            'uri': docPath.page,
            ...data.localData
        };
        framework.post(url('api/log_generic_event'), localData);
    }

    static trackInGA(event, p) {
        var params = p || {};
        params = Object.assign({
            page: docPath.page
        }, params);
        if (typeof gtag !== 'undefined') {
            gtag('event', event, params);
        }
    }

    static trackConversion(key, p) {
        var params = p || {};
        params = Object.assign({
            'send_to': 'AW-1071951115/'+key
        }, params);
        if (typeof gtag !== 'undefined') {
            gtag('event', 'conversion', params);
        }
    }
}

export {
    Analytics
}